<template>
  <div v-if="currentRole === 4 || currentRole === 5" class="absent">
    <h2>Make-up lessons</h2>
    <v-row :class="'absent-header justify-space-between align-center'">
      <v-col>
        <div :class="' d-flex justify-start'">
          <v-select
            v-model="locationID"
            item-text="location"
            append-icon="keyboard_arrow_down"
            item-value="locationID"
            :items="listLocation"
            label="Center"
            outlined
            :class="'select-input'"
            dense
          ></v-select>

          <v-text-field
            type="date"
            v-model="fromDate"
            label="From date"
            :class="'select-input'"
            outlined
            dense
            hide-details
            @input="changeDate"
          />
          <v-text-field
            type="date"
            v-model="toDate"
            label="To date"
            :class="'select-input'"
            outlined
            dense
            hide-details
            @input="changeDate"
          /></div
      ></v-col>
    </v-row>
    <h4>Total : {{ listStudent.length }} students</h4>
    <template v-if="loading">
      <div class="pa-6">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <template>
      <v-data-table
        :headers="headers"
        :items="filtered"
        v-if="!loading"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        hide-default-footer
        hide-default-header
        class="elevation-0 makeup-table"
        id="attendTable"
        fixed-header
        height="69vh"
      >
        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
            <tr class="header-absent">
              <th
                v-for="(header, index) in props.headers"
                :key="header.text + '' + index"
                role="columnheader"
                scope="col"
                class="text-center font-weight-light headTitle"
              >
                <span style="color: white">{{ header.text }}</span>
              </th>
            </tr>
          </thead>
          <tr class="f">
            <td
              v-for="header in props.headers"
              :key="header.value"
              :style="{
                position: 'sticky',
                top: '50px',
                background: '#ffff',
                borderBottom: '1px solid  #d1d4d4',
              }"
            >
              <div :class="'d-flex justify-center align-center'" v-if="filters[header.value]">
                <Autocomplete
                  v-if="filters.hasOwnProperty(header.value)"
                  @setFilter="setFilter"
                  :styleBorder="'border-bottom'"
                  :keyFilter="header.value"
                  :selectedInit="filters[header.value]"
                  :listItem="groupColumnValueList(header.value)"
                />
              </div>
              <div v-else>
                <td></td>
              </div>
            </td>
          </tr>
        </template>

        <template v-slot:[`item.classCode`]="{ item }">
          <p class="link w-100" style="width: 100px" @click="viewClassDetail(item.classID)">
            {{ item.classCode }}
          </p>
        </template>
        <template v-slot:[`item.no`]="{ index }">
          <p>{{ index + 20 * (page - 1) + 1 }}</p>
        </template>
        <template v-slot:[`item.classGroup`]="{ item }">
          <p :class="'type-makup lesson-type'">
            {{ item.classGroup }}
          </p>
        </template>
        <template v-slot:[`item.studentCode`]="{ item }">
          <p class="link w-80" @click="viewStudent(item.stuUserID)">{{ item.studentCode }}</p>
        </template>
        <template v-slot:[`item.makeUpShift`]="{ item }">
          <p class="w-80">{{ item.makeUpShift }}</p>
        </template>
        <template v-slot:[`item.classShift`]="{ item }">
          <p class="w-80">{{ item.classShift }}</p>
        </template>
        <template v-slot:[`item.studentName`]="{ item }">
          <p class="link w-200" @click="viewStudent(item.stuUserID)">{{ item.studentName }}</p>
        </template>
        <template v-slot:[`item.qc`]="{ item }">
          <p class="link w-200" @click="viewStaff(item.qcUserID)">{{ item.qc }}</p>
        </template>
        <template v-slot:[`item.makeUpClassCode`]="{ item }">
          <p class="link w-100" style="width: 100px" @click="viewClassDetail(item.makeUpClassID)">
            {{ item.makeUpClassCode }}
          </p>
        </template>
        <template v-slot:[`item.attendStatus`]="{ item }">
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  @click="openQuickAttendance(item)"
                  :style="{
                    width: 'fit-content',
                    margin: '0 auto',
                  }"
                  class="quick-attend-icon"
                >
                  <img
                    :style="{
                      width: item.attendStatus === 'Absent' ? '34px' : '27px',
                    }"
                    :src="
                      item.attendStatus === 'Absent'
                        ? require('../../../assets/images/icon-close-red.svg')
                        : item.attendStatus === 'Attended'
                        ? require('../../../assets/images/icon-done.svg')
                        : require('../../../assets/images/icon-pendding.svg')
                    "
                    alt=""
                    srcset=""
                  />
                </div>
              </template>
              <span>Attend</span>
            </v-tooltip>
            <div v-show="overlay" :class="'h'">
              <div class="card-i">
                <v-card :style="{ boxShadow: 'none' }" light :width="screen < 700 ? '300' : '350'">
                  <v-card-title
                    class="text-h5 text-content"
                    :style="{ fontWeight: 700 }"
                    style="background-color: #0b2585; color: #ffffff"
                  >
                    Quick attendance
                  </v-card-title>

                  <div style="padding: 16px 24px 0">
                    <div class="quick-attendance-top">
                      <p>
                        <span class="text-content">Class: </span>
                        <span class="text-sub-content"
                          >{{ makeUpClassCode }} - Date: {{ makeUpDate }}</span
                        >
                      </p>
                      <p>
                        <span class="text-content">Student: </span>
                        <span class="text-sub-content">{{ studentCode }} - {{ studentName }}</span>
                      </p>
                    </div>
                    <div class="quick-attendance-bot">
                      <div class="attendance-form">
                        <p class="text-content" style="margin-bottom: 15px">Attendance:</p>
                        <div
                          class="d-flex align-center"
                          style="position: relative; margin-bottom: 10px"
                        >
                          <label>
                            <input type="radio" value="Attended" v-model="attendanceStatus" />
                            <span
                              v-if="
                                attendanceStatus == '' ||
                                attendanceStatus == null ||
                                attendanceStatus == 'Absent' ||
                                attendanceStatus == 'Pending'
                              "
                              class="checkbox-custom-left"
                              >Attend</span
                            >
                            <span v-else class="checkbox-custom-left checkbox-custom-left-focus"
                              >Attend</span
                            >
                          </label>
                          <label>
                            <input type="radio" value="Absent" v-model="attendanceStatus" />

                            <span
                              v-if="
                                attendanceStatus == '' ||
                                attendanceStatus == null ||
                                attendanceStatus == 'Attended' ||
                                attendanceStatus == 'Pending'
                              "
                              class="checkbox-custom-right"
                              >Absent</span
                            >
                            <span v-else class="checkbox-custom-right checkbox-custom-right-focus"
                              >Absent</span
                            >
                          </label>
                        </div>
                        <div style="position: relative">
                          <v-text-field
                            class="text-field-custom-1"
                            v-model="remarkAttendance"
                            dense
                            placeholder="Nhập lý do nghỉ"
                            outlined
                            height="40"
                            style="width: 300px"
                            :disabled="attendanceStatus !== 'Absent'"
                          ></v-text-field>
                        </div>
                      </div>
                      <div class="homework-form">
                        <p class="text-content" style="margin-bottom: 15px">Homework:</p>
                        <div
                          class="d-flex align-center"
                          style="position: relative; margin-bottom: 10px"
                        >
                          <label>
                            <input type="radio" v-model="homeworkStatus" value="Yes" />
                            <span
                              v-if="
                                homeworkStatus == '' ||
                                homeworkStatus == null ||
                                homeworkStatus == 'No'
                              "
                              class="checkbox-custom-left"
                              >Done</span
                            >
                            <span v-else class="checkbox-custom-left checkbox-custom-left-focus"
                              >Done</span
                            >
                          </label>
                          <label>
                            <input type="radio" v-model="homeworkStatus" value="No" />
                            <span
                              v-if="
                                homeworkStatus == '' ||
                                homeworkStatus == null ||
                                homeworkStatus == 'Yes'
                              "
                              class="checkbox-custom-right"
                              style="width: 80px"
                              >Not done</span
                            >
                            <span
                              v-else
                              class="checkbox-custom-right checkbox-custom-right-focus"
                              style="width: 80px"
                              >Not done</span
                            >
                          </label>
                        </div>
                        <div style="position: relative">
                          <v-text-field
                            class="text-field-custom-2"
                            v-model="remarkHomeWork"
                            dense
                            placeholder="Ghi chú"
                            outlined
                            height="40px"
                            style="width: 300px"
                          >
                          </v-text-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <div :class="'mb-4 d-flex'" class="btnNote">
                      <v-btn elevation="2" large @click="overlay = false" style="margin-left: 0"
                        >Cancel</v-btn
                      >
                      <v-btn
                        elevation="2"
                        large
                        color="primary"
                        :disabled="
                          attendanceStatus === 'Pending' &&
                          !homeworkStatus &&
                          !remarkAttendance &&
                          !remarkHomeWork
                        "
                        @click="saveAttendance(studentAttendance)"
                        style="margin-right: 5px"
                        >Save</v-btn
                      >
                    </div>
                  </v-card-actions>
                </v-card>
              </div>
            </div>
          </td>
        </template>
        <template v-slot:[`item.ec`]="{ item }">
          <p class="link w-200" @click="viewStudent(item.ecUserID)">{{ item.ec }}</p>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <img
                :style="{
                  opacity:
                    item.makeUpDate && dayjs().isBefore(dayjs(item.makeUpDateWithShift)) ? 1 : 0.4,
                }"
                v-bind="attrs"
                v-on="on"
                @click="
                  item.makeUpDate &&
                    dayjs().isBefore(dayjs(item.makeUpDateWithShift)) &&
                    openForm(item)
                "
                src="../../../assets/images/icon-edit-pen.svg"
              />
            </template>
            <span>
              {{
                item.makeUpDate && dayjs().isBefore(dayjs(item.makeUpDateWithShift))
                  ? 'Add/Edit'
                  : "Can't edit "
              }}</span
            >
          </v-tooltip>
        </template>
      </v-data-table>
      <div v-if="!isLoading" color="#fff" class="pl-10 mt-3 pr-10">
        <v-row>
          <v-col md="2">
            <v-select
              outlined
              hide-details
              dense
              :value="itemsPerPage"
              :items="[20, 50, 100, 200]"
              @change="itemsPerPage = parseInt($event, 10)"
              style="height: 32px"
            ></v-select>
          </v-col>
          <v-col md="4" class="d-flex align-center justify-end">
            <h3></h3>
          </v-col>
          <v-col md="6" class="d-flex justify-end">
            <v-pagination
              v-model="page"
              :length="pageCount"
              elevation="0"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
      <FormMakeupLesson
        v-if="dialogForm"
        @setLog="setLog"
        @callData="callDataWithNoLoading"
        :makeupInfor="{
          ...makeupInfor,
          classDate: makeupInfor.classDate.split('/').reverse().join('-'),
        }"
        :dialog="dialogForm"
      />
    </template>
  </div>
</template>

<script>
import { makeuplesson } from '@/api/makeuplesson'
import { class_managerment } from '@/api/class-management.js'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import FormMakeupLesson from '@/components/StatisticManagement/FormMakeupLesson.vue'
import dayjs from 'dayjs'

export default {
  components: { Autocomplete, FormMakeupLesson },
  data() {
    return {
      studentAttendance: {},
      classID: '',
      scheduleID: '',
      attendanceStatus: '',
      homeworkStatus: '',
      remarkHomeWork: '',
      remarkAttendance: '',
      makeUpClassCode: '',
      makeUpDate: '',
      studentCode: '',
      studentName: '',
      isEdit: false,
      overlay: false,
      dialog: false,
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      listStudent: [],
      isLoading: false,
      page: 1,
      dayjs: dayjs,
      pageCount: 0,
      itemsPerPage: 100,
      makeupInfor: {},
      dialogForm: false,
      fromDate: new Date().toISOString().split('T')[0],
      toDate: new Date().toISOString().split('T')[0],
      listLocation: [],
      locationID: 1,
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
        },
        {
          text: 'Student code',
          value: 'studentCode',
          sortable: false,
        },
        {
          text: 'Student name',
          value: 'studentName',
          sortable: false,
        },
        {
          text: 'Make-up class',
          value: 'makeUpClassCode',
          sortable: false,
        },
        {
          text: 'Make-up date',
          value: 'makeUpDate',
          sortable: false,
        },
        {
          text: 'Make-up shift',
          value: 'makeUpShift',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'attendStatus',
          sortable: false,
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: false,
        },
        {
          text: 'QC',
          value: 'qc',
          sortable: false,
        },
        {
          text: 'EC',
          value: 'ec',
          sortable: false,
        },

        {
          text: 'Absent class',
          value: 'classCode',
          sortable: false,
        },
        {
          text: 'Absent date',
          value: 'classDate',
          sortable: false,
        },
        {
          text: 'Absent shift',
          value: 'classShift',
          sortable: false,
        },

        {
          text: 'Action',
          value: 'action',
          sortable: false,
        },
      ],
      filters: {
        qc: [],
        ec: [],
        makeUpClassCode: [],
        classCode: [],
        studentCode: [],
        classDate: [],
        attendStatus: [],
        studentName: [],
        classTime: [],
        phone: [],
        makeUpShift: [],
        makeUpDate: [],
        classShift: [],
      },
    }
  },
  computed: {
    filtered() {
      return this.listStudent.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
  },
  setup() {
    return {}
  },
  created() {
    this.listLocation = JSON.parse(localStorage.getItem('currentUser')).locationPermissions
    this.locationID = parseInt(localStorage.getItem('currentCenter'))
    if (localStorage.getItem('makeupCenter'))
      this.locationID = parseInt(localStorage.getItem('makeupCenter'))
    if (localStorage.getItem('makeupTo'))
      this.toDate = dayjs(localStorage.getItem('makeupTo')).format('YYYY-MM-DD')
    if (localStorage.getItem('makeupFrom'))
      this.fromDate = dayjs(localStorage.getItem('makeupFrom')).format('YYYY-MM-DD')
    if (localStorage.getItem('makeupFilter'))
      this.filters = JSON.parse(localStorage.getItem('makeupFilter'))
    this.init()
  },

  watch: {
    locationID: function () {
      localStorage.setItem('makeupCenter', this.locationID)
      this.init()
    },
  },
  methods: {
    getTimeInobject(shift) {
      if (shift && shift.split('-')[0].indexOf(':') !== -1) {
        let arrShift = shift.split('-')[0].split(':')
        return {
          hour: !isNaN(arrShift[0]) ? parseInt(arrShift[0]) : null,
          min: !isNaN(arrShift[1]) ? parseInt(arrShift[1]) : null,
        }
      }
      return null
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('makeupFilter', JSON.stringify(this.filters))
    },
    openForm(makeupInfor) {
      this.makeupInfor = makeupInfor
      this.dialogForm = true
    },
    resetFilter() {
      this.filters = {
        qc: [],
        ec: [],
        makeUpClassCode: [],
        classCode: [],
        studentCode: [],
        classDate: [],
        attendStatus: [],
        studentName: [],
        classTime: [],
        phone: [],
        makeUpShift: [],
        makeUpDate: [],
      }
    },
    setLog(data) {
      this.dialogForm = data.openDialog ? data.openDialog : false
      this.makeupInfor = data.makeup ? data.makeup : ''
    },
    groupColumnValueList(val) {
      return this.listStudent.map(d => d[val]).filter(y => y)
    },

    viewStudent(studentID) {
      this.$router.push({
        name: 'account-management-view-account',
        params: {
          userId: studentID,
        },
      })
    },
    viewStaff(userID) {
      this.$router.push({
        name: 'account-management-view-account',
        params: {
          userId: userID,
        },
      })
    },
    viewClassDetail(classID) {
      this.$router.push({
        name: 'class-management-detail',
        query: {
          classID: classID,
        },
      })
    },
    changeDate() {
      localStorage.setItem('makeupFrom', this.fromDate)
      localStorage.setItem('makeupTo', this.toDate)
      this.init()
    },
    async init() {
      this.loading = true
      await makeuplesson.getMakeupList(this.locationID, this.fromDate, this.toDate).then(res => {
        this.listStudent = res.map(item => {
          return {
            ...item,
            makeUpDateWithShift:
              this.getTimeInobject(item.makeUpShift) &&
              dayjs(item.makeUpDate)
                .add(this.getTimeInobject(item.makeUpShift).hour, 'hour')
                .add(this.getTimeInobject(item.makeUpShift).min, 'minute'),
            attendStatus: item.attendStatus === null ? 'Pending' : item.attendStatus,
            classGroup: item.classGroup === 'Public' ? 'Official' : item.classGroup,
            makeUpDate: dayjs(item.makeUpDate).format('DD/MM/YYYY'),
            classDate: dayjs(item.classDate).format('DD/MM/YYYY'),
          }
        })
        this.loading = false
      })
    },
    async callDataWithNoLoading() {
      await makeuplesson.getMakeupList(this.locationID, this.fromDate, this.toDate).then(res => {
        this.listStudent = res.map(item => {
          return {
            ...item,
            makeUpDateWithShift:
              this.getTimeInobject(item.makeUpShift) &&
              dayjs(item.makeUpDate)
                .add(this.getTimeInobject(item.makeUpShift).hour, 'hour')
                .add(this.getTimeInobject(item.makeUpShift).min, 'minute'),
            attendStatus: item.attendStatus ? 'Pending' : item.attendStatus,
            classGroup: item.classGroup === 'Public' ? 'Official' : item.classGroup,
            makeUpDate: dayjs(item.makeUpDate).format('DD/MM/YYYY'),
            classDate: dayjs(item.classDate).format('DD/MM/YYYY'),
          }
        })
      })
    },

    async reload() {
      this.overlay = false
      this.init()
    },
    openQuickAttendance(item) {
      this.studentAttendance = item
      this.classID = item.makeUpClassID
      this.scheduleID = item.scheduleID
      this.makeUpClassCode = item.makeUpClassCode
      this.makeUpDate = item.makeUpDate
      this.studentCode = item.studentCode
      this.studentName = item.studentName
      this.attendanceStatus = item.attendStatus
      this.homeworkStatus = item.homeworkStatus
      this.remarkAttendance = item.remarkAttendance
      this.remarkHomeWork = item.remarkHomeWork

      this.overlay = true
    },
    async saveAttendance(studentAttendance) {
      const student = {
        studentID: studentAttendance.studentID,
        registrationID: studentAttendance.registrationID,
        ordinalNo: studentAttendance.makeUpLessonNo,
        isJoinToLesson:
          this.attendanceStatus == 'Attended'
            ? true
            : this.attendanceStatus === null ||
              this.attendanceStatus === '' ||
              this.attendanceStatus === 'Pending'
            ? null
            : false,
        homeworkStatus: this.homeworkStatus,
        remarkAttendance: this.remarkAttendance,
        remarkHomeWork: this.remarkHomeWork,
      }
      const students = [student]
      const body = {
        students: students,
      }
      await class_managerment
        .updateAttendanceStudent(this.classID, this.scheduleID, body)
        .then(res => {
          if (res) {
            this.dialog = true
            this.dialog = false
          }
        })
      this.reload()
      this.overlay = false
    },
  },
}
</script>

<style lang="scss" scoped>
.lesson-type {
  width: 80px;
  height: 30px;
  color: #ffff;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;

  &.type-public {
    background: #27ae60;
  }
  &.type-makup {
    background: #f6b91a;
  }
}
.absent-btn-download {
  width: fit-content;
  margin-left: auto;
}
.select-input {
  max-width: 200px;
  margin-right: 20px !important;
}
h4 {
  margin-bottom: 14px;
}
h2 {
  margin-bottom: 20px;
}
.absent {
  padding: 20px 36px;
}
.makeup-table {
  border-radius: 5px;
  overflow: hidden;
}
::v-deep .headTitle {
  background-color: #0b2585 !important;
  height: 30px !important;
  font-size: 14px !important;
  border: 0 !important;
  color: white !important;
}

.v-text-field {
  width: 200px;
}
tbody tr:nth-of-type(odd) {
  // background: rgb(235, 229, 229) !important;
  color: #000 !important;
}
tbody tr:nth-of-type(even) {
  // background: rgb(235, 229, 229) !important;
  background: #000 !important;
}
#attendTable {
  display: block;
  white-space: nowrap;
}
.header-absent {
  background: #0b2585;
}
.header-absent th:nth-child(1) {
  z-index: 7;
  span {
    width: 20px;
  }
  position: sticky;
  left: 0px;
}
.header-absent th:nth-child(2) {
  z-index: 7;
  span {
    width: 70px;
  }
  position: sticky;
  left: 45px;
}
.header-absent th:nth-child(3) {
  z-index: 7;
  span {
    width: 250px;
  }
  position: sticky;
  left: 140px;
}

.f td:nth-of-type(1) {
  z-index: 6 !important;
  left: 0;
}
.f td:nth-of-type(2) {
  z-index: 6 !important;
  left: 40px;
}
.f td:nth-of-type(3) {
  z-index: 6 !important;
  left: 140px;
}

#attendTable th {
  padding: 16px 7px;
  text-align: center;
  font-weight: 400;
}
#attendTable td {
  border-bottom: 1px solid #d1d4d4;
}

#attendTable tbody:nth-of-type(even) tr td {
  background-color: #f3faff !important;
}

#attendTable tbody tr:nth-of-type(odd):hover td {
  background-color: #d7dbdd !important;
}
#attendTable tbody:nth-of-type(even) tr:hover td {
  background-color: #dbecf8 !important;
}
#attendTable tbody tr:nth-of-type(even) {
  background-color: #eeeeee !important;
}

#attendTable tbody tr td:nth-of-type(1) {
  z-index: 4;
  p {
    width: 20px;
  }
  position: sticky;
  left: 0px;
  background: #fff;
}
#attendTable tbody tr td:nth-of-type(2) {
  z-index: 4;
  p {
    width: 70px;
  }
  position: sticky;
  left: 40px;
  background: #fff;
  color: #427df2 !important;
}
#attendTable tbody tr td:nth-of-type(3) {
  p {
    width: 170px;
  }
  position: sticky;
  left: 130px;
  z-index: 4;
  background: #fff;
  color: #427df2 !important;
}
#attendTable tbody tr td:nth-child(1) {
  width: 20px;
}
.btn-download {
  width: fit-content;
  margin-left: auto;
}

.quick-attend-icon:hover {
  cursor: pointer;

  img {
    transition: all 0.5s;
    transform: translateY(-5px);
  }
}
.link {
  cursor: pointer;

  color: #427df2 !important;
}

.link:hover {
  text-decoration: underline;
  font-weight: 700;
}
.h {
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  z-index: 1000;
  left: 0;
}
.card-i {
  width: 500px;
  position: absolute;
  top: 50%;

  left: 58%;
  transform: translate(-50%, -50%);
}
.btnNote {
  margin-top: 20px;
}
.btnNote button {
  margin-left: 20px;
  min-width: 150px !important;
}

.text-content {
  font-weight: 600;
  color: #0c2e68;
  margin-bottom: 5px;
}
.text-sub-content {
  color: rgba(12, 46, 104, 0.8);
  margin-bottom: 5px;
}

.checkbox-custom-right,
.checkbox-custom-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;

  width: 56px;
  height: 25px;
  font-size: 14px;

  background: #ffffff;
  border: 1px solid #dae6f7;
  border-radius: 38px;

  z-index: 0;
}

.checkbox-custom-left {
  position: absolute;
  top: -4px;
  left: 0px;
}
.checkbox-custom-right {
  position: absolute;
  top: -4px;
  left: 66px;
}

.checkbox-custom-left-focus {
  border-color: #4adc88;
  background: #ddffe7;
  color: #0cb754;
}
.checkbox-custom-right-focus {
  border-color: #ec1c24;
  background: #ffeaeb;
  color: #ec1c24;
}

.text-field-custom-1 {
  width: 100px;
}
.text-field-custom-2 {
  width: 100px;
}
</style>
