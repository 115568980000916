var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRole === 4 || _vm.currentRole === 5)?_c('div',{staticClass:"absent"},[_c('h2',[_vm._v("Make-up lessons")]),_c('v-row',{class:'absent-header justify-space-between align-center'},[_c('v-col',[_c('div',{class:' d-flex justify-start'},[_c('v-select',{class:'select-input',attrs:{"item-text":"location","append-icon":"keyboard_arrow_down","item-value":"locationID","items":_vm.listLocation,"label":"Center","outlined":"","dense":""},model:{value:(_vm.locationID),callback:function ($$v) {_vm.locationID=$$v},expression:"locationID"}}),_c('v-text-field',{class:'select-input',attrs:{"type":"date","label":"From date","outlined":"","dense":"","hide-details":""},on:{"input":_vm.changeDate},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}}),_c('v-text-field',{class:'select-input',attrs:{"type":"date","label":"To date","outlined":"","dense":"","hide-details":""},on:{"input":_vm.changeDate},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)])],1),_c('h4',[_vm._v("Total : "+_vm._s(_vm.listStudent.length)+" students")]),(_vm.loading)?[_c('div',{staticClass:"pa-6"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),[(!_vm.loading)?_c('v-data-table',{staticClass:"elevation-0 makeup-table",attrs:{"headers":_vm.headers,"items":_vm.filtered,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","hide-default-header":"","id":"attendTable","fixed-header":"","height":"69vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',{staticClass:"header-absent"},_vm._l((props.headers),function(header,index){return _c('th',{key:header.text + '' + index,staticClass:"text-center font-weight-light headTitle",attrs:{"role":"columnheader","scope":"col"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(header.text))])])}),0)]),_c('tr',{staticClass:"f"},_vm._l((props.headers),function(header){return _c('td',{key:header.value,style:({
              position: 'sticky',
              top: '50px',
              background: '#ffff',
              borderBottom: '1px solid  #d1d4d4',
            })},[(_vm.filters[header.value])?_c('div',{class:'d-flex justify-center align-center'},[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_c('div',[_c('td')])])}),0)]}},{key:"item.classCode",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"link w-100",staticStyle:{"width":"100px"},on:{"click":function($event){return _vm.viewClassDetail(item.classID)}}},[_vm._v(" "+_vm._s(item.classCode)+" ")])]}},{key:"item.no",fn:function(ref){
            var index = ref.index;
return [_c('p',[_vm._v(_vm._s(index + 20 * (_vm.page - 1) + 1))])]}},{key:"item.classGroup",fn:function(ref){
            var item = ref.item;
return [_c('p',{class:'type-makup lesson-type'},[_vm._v(" "+_vm._s(item.classGroup)+" ")])]}},{key:"item.studentCode",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"link w-80",on:{"click":function($event){return _vm.viewStudent(item.stuUserID)}}},[_vm._v(_vm._s(item.studentCode))])]}},{key:"item.makeUpShift",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"w-80"},[_vm._v(_vm._s(item.makeUpShift))])]}},{key:"item.classShift",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"w-80"},[_vm._v(_vm._s(item.classShift))])]}},{key:"item.studentName",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"link w-200",on:{"click":function($event){return _vm.viewStudent(item.stuUserID)}}},[_vm._v(_vm._s(item.studentName))])]}},{key:"item.qc",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"link w-200",on:{"click":function($event){return _vm.viewStaff(item.qcUserID)}}},[_vm._v(_vm._s(item.qc))])]}},{key:"item.makeUpClassCode",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"link w-100",staticStyle:{"width":"100px"},on:{"click":function($event){return _vm.viewClassDetail(item.makeUpClassID)}}},[_vm._v(" "+_vm._s(item.makeUpClassCode)+" ")])]}},{key:"item.attendStatus",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"quick-attend-icon",style:({
                  width: 'fit-content',
                  margin: '0 auto',
                }),on:{"click":function($event){return _vm.openQuickAttendance(item)}}},'div',attrs,false),on),[_c('img',{style:({
                    width: item.attendStatus === 'Absent' ? '34px' : '27px',
                  }),attrs:{"src":item.attendStatus === 'Absent'
                      ? require('../../../assets/images/icon-close-red.svg')
                      : item.attendStatus === 'Attended'
                      ? require('../../../assets/images/icon-done.svg')
                      : require('../../../assets/images/icon-pendding.svg'),"alt":"","srcset":""}})])]}}],null,true)},[_c('span',[_vm._v("Attend")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.overlay),expression:"overlay"}],class:'h'},[_c('div',{staticClass:"card-i"},[_c('v-card',{style:({ boxShadow: 'none' }),attrs:{"light":"","width":_vm.screen < 700 ? '300' : '350'}},[_c('v-card-title',{staticClass:"text-h5 text-content",staticStyle:{"background-color":"#0b2585","color":"#ffffff"},style:({ fontWeight: 700 })},[_vm._v(" Quick attendance ")]),_c('div',{staticStyle:{"padding":"16px 24px 0"}},[_c('div',{staticClass:"quick-attendance-top"},[_c('p',[_c('span',{staticClass:"text-content"},[_vm._v("Class: ")]),_c('span',{staticClass:"text-sub-content"},[_vm._v(_vm._s(_vm.makeUpClassCode)+" - Date: "+_vm._s(_vm.makeUpDate))])]),_c('p',[_c('span',{staticClass:"text-content"},[_vm._v("Student: ")]),_c('span',{staticClass:"text-sub-content"},[_vm._v(_vm._s(_vm.studentCode)+" - "+_vm._s(_vm.studentName))])])]),_c('div',{staticClass:"quick-attendance-bot"},[_c('div',{staticClass:"attendance-form"},[_c('p',{staticClass:"text-content",staticStyle:{"margin-bottom":"15px"}},[_vm._v("Attendance:")]),_c('div',{staticClass:"d-flex align-center",staticStyle:{"position":"relative","margin-bottom":"10px"}},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attendanceStatus),expression:"attendanceStatus"}],attrs:{"type":"radio","value":"Attended"},domProps:{"checked":_vm._q(_vm.attendanceStatus,"Attended")},on:{"change":function($event){_vm.attendanceStatus="Attended"}}}),(
                              _vm.attendanceStatus == '' ||
                              _vm.attendanceStatus == null ||
                              _vm.attendanceStatus == 'Absent' ||
                              _vm.attendanceStatus == 'Pending'
                            )?_c('span',{staticClass:"checkbox-custom-left"},[_vm._v("Attend")]):_c('span',{staticClass:"checkbox-custom-left checkbox-custom-left-focus"},[_vm._v("Attend")])]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attendanceStatus),expression:"attendanceStatus"}],attrs:{"type":"radio","value":"Absent"},domProps:{"checked":_vm._q(_vm.attendanceStatus,"Absent")},on:{"change":function($event){_vm.attendanceStatus="Absent"}}}),(
                              _vm.attendanceStatus == '' ||
                              _vm.attendanceStatus == null ||
                              _vm.attendanceStatus == 'Attended' ||
                              _vm.attendanceStatus == 'Pending'
                            )?_c('span',{staticClass:"checkbox-custom-right"},[_vm._v("Absent")]):_c('span',{staticClass:"checkbox-custom-right checkbox-custom-right-focus"},[_vm._v("Absent")])])]),_c('div',{staticStyle:{"position":"relative"}},[_c('v-text-field',{staticClass:"text-field-custom-1",staticStyle:{"width":"300px"},attrs:{"dense":"","placeholder":"Nhập lý do nghỉ","outlined":"","height":"40","disabled":_vm.attendanceStatus !== 'Absent'},model:{value:(_vm.remarkAttendance),callback:function ($$v) {_vm.remarkAttendance=$$v},expression:"remarkAttendance"}})],1)]),_c('div',{staticClass:"homework-form"},[_c('p',{staticClass:"text-content",staticStyle:{"margin-bottom":"15px"}},[_vm._v("Homework:")]),_c('div',{staticClass:"d-flex align-center",staticStyle:{"position":"relative","margin-bottom":"10px"}},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.homeworkStatus),expression:"homeworkStatus"}],attrs:{"type":"radio","value":"Yes"},domProps:{"checked":_vm._q(_vm.homeworkStatus,"Yes")},on:{"change":function($event){_vm.homeworkStatus="Yes"}}}),(
                              _vm.homeworkStatus == '' ||
                              _vm.homeworkStatus == null ||
                              _vm.homeworkStatus == 'No'
                            )?_c('span',{staticClass:"checkbox-custom-left"},[_vm._v("Done")]):_c('span',{staticClass:"checkbox-custom-left checkbox-custom-left-focus"},[_vm._v("Done")])]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.homeworkStatus),expression:"homeworkStatus"}],attrs:{"type":"radio","value":"No"},domProps:{"checked":_vm._q(_vm.homeworkStatus,"No")},on:{"change":function($event){_vm.homeworkStatus="No"}}}),(
                              _vm.homeworkStatus == '' ||
                              _vm.homeworkStatus == null ||
                              _vm.homeworkStatus == 'Yes'
                            )?_c('span',{staticClass:"checkbox-custom-right",staticStyle:{"width":"80px"}},[_vm._v("Not done")]):_c('span',{staticClass:"checkbox-custom-right checkbox-custom-right-focus",staticStyle:{"width":"80px"}},[_vm._v("Not done")])])]),_c('div',{staticStyle:{"position":"relative"}},[_c('v-text-field',{staticClass:"text-field-custom-2",staticStyle:{"width":"300px"},attrs:{"dense":"","placeholder":"Ghi chú","outlined":"","height":"40px"},model:{value:(_vm.remarkHomeWork),callback:function ($$v) {_vm.remarkHomeWork=$$v},expression:"remarkHomeWork"}})],1)])])]),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"btnNote",class:'mb-4 d-flex'},[_c('v-btn',{staticStyle:{"margin-left":"0"},attrs:{"elevation":"2","large":""},on:{"click":function($event){_vm.overlay = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticStyle:{"margin-right":"5px"},attrs:{"elevation":"2","large":"","color":"primary","disabled":_vm.attendanceStatus === 'Pending' &&
                        !_vm.homeworkStatus &&
                        !_vm.remarkAttendance &&
                        !_vm.remarkHomeWork},on:{"click":function($event){return _vm.saveAttendance(_vm.studentAttendance)}}},[_vm._v("Save")])],1)],1)],1)],1)])],1)]}},{key:"item.ec",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"link w-200",on:{"click":function($event){return _vm.viewStudent(item.ecUserID)}}},[_vm._v(_vm._s(item.ec))])]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({style:({
                opacity:
                  item.makeUpDate && _vm.dayjs().isBefore(_vm.dayjs(item.makeUpDateWithShift)) ? 1 : 0.4,
              }),attrs:{"src":require("../../../assets/images/icon-edit-pen.svg")},on:{"click":function($event){item.makeUpDate &&
                  _vm.dayjs().isBefore(_vm.dayjs(item.makeUpDateWithShift)) &&
                  _vm.openForm(item)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.makeUpDate && _vm.dayjs().isBefore(_vm.dayjs(item.makeUpDateWithShift)) ? 'Add/Edit' : "Can't edit "))])])]}}],null,true)}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"pl-10 mt-3 pr-10",attrs:{"color":"#fff"}},[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"md":"4"}},[_c('h3')]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"elevation":"0","total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1):_vm._e(),(_vm.dialogForm)?_c('FormMakeupLesson',{attrs:{"makeupInfor":Object.assign({}, _vm.makeupInfor,
        {classDate: _vm.makeupInfor.classDate.split('/').reverse().join('-')}),"dialog":_vm.dialogForm},on:{"setLog":_vm.setLog,"callData":_vm.callDataWithNoLoading}}):_vm._e()]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }